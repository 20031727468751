<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#707487;"
      d="M493.037,402.963H18.963C8.489,402.963,0,394.473,0,384V42.667c0-10.473,8.489-18.963,18.963-18.963
	h474.074c10.473,0,18.963,8.489,18.963,18.963V384C512,394.473,503.511,402.963,493.037,402.963z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M0,346.074V384c0,10.472,8.491,18.963,18.963,18.963h188.576l-8.428,75.852h113.778l-8.428-75.852
	h188.576c10.472,0,18.963-8.491,18.963-18.963v-37.926H0z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M464.593,355.556H47.407c-5.236,0-9.481-4.245-9.481-9.482V71.111c0-5.236,4.245-9.481,9.481-9.481
	h417.185c5.236,0,9.481,4.245,9.481,9.481v274.963C474.074,351.31,469.829,355.556,464.593,355.556z"
    />
    <polygon
      style="fill:#F1F3C5;"
      points="417.185,128 312.889,241.778 312.889,355.556 417.185,355.556 "
    />
    <polygon
      style="fill:#FFF3C9;"
      points="199.111,184.889 303.407,241.778 303.407,355.556 199.111,355.556 "
    />
    <polygon
      style="fill:#FFDED3;"
      points="199.111,184.889 94.815,289.185 94.815,355.556 199.111,355.556 "
    />
    <g>
      <rect
        x="85.333"
        y="289.185"
        style="fill:#D5DCED;"
        width="18.963"
        height="66.37"
      />
      <rect
        x="300.243"
        y="232.296"
        style="fill:#D5DCED;"
        width="18.963"
        height="123.259"
      />
      <rect
        x="192.794"
        y="184.889"
        style="fill:#D5DCED;"
        width="18.963"
        height="170.667"
      />
      <rect
        x="407.704"
        y="137.481"
        style="fill:#D5DCED;"
        width="18.963"
        height="218.074"
      />
    </g>
    <path
      style="fill:#707487;"
      d="M350.815,488.296h-189.63c-5.241,0-9.481-4.241-9.481-9.481s4.241-9.481,9.481-9.481h189.63
	c5.241,0,9.481,4.241,9.481,9.481S356.056,488.296,350.815,488.296z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M94.815,298.667c-2.472,0-4.945-0.964-6.805-2.88c-3.648-3.759-3.556-9.759,0.204-13.408
	l107.454-104.296c2.953-2.88,7.426-3.49,11.038-1.574l101.083,53.509L410.297,121.49c3.583-3.806,9.583-3.981,13.398-0.379
	c3.806,3.592,3.981,9.593,0.379,13.399L316.621,248.287c-2.917,3.102-7.556,3.871-11.324,1.87l-101.37-53.666l-102.51,99.5
	C99.575,297.778,97.195,298.667,94.815,298.667z"
    />
    <polygon
      style="fill:#464655;"
      points="304.461,402.963 207.539,402.963 205.432,421.926 306.568,421.926 "
    />
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M85.333,325.771c3.047,0.789,6.188,1.34,9.481,1.34c3.294,0,6.434-0.551,9.481-1.34v-36.585H85.333
		V325.771z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M407.704,164.585c3.047,0.789,6.188,1.34,9.481,1.34c3.294,0,6.434-0.551,9.481-1.34V128h-18.963
		V164.585z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M300.247,278.363c3.047,0.789,6.188,1.34,9.481,1.34c3.294,0,6.434-0.551,9.482-1.34v-36.585
		h-18.963V278.363L300.247,278.363z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M192.791,221.474c3.047,0.789,6.188,1.34,9.481,1.34c3.294,0,6.434-0.551,9.481-1.34v-36.585
		h-18.963V221.474z"
      />
    </g>
    <circle style="fill:#FF507D;" cx="94.815" cy="289.185" r="23.704" />
    <circle style="fill:#B4E66E;" cx="417.185" cy="128" r="23.704" />
    <circle style="fill:#FFDC64;" cx="309.724" cy="241.778" r="23.704" />
    <circle style="fill:#FF8C66;" cx="202.276" cy="184.889" r="23.704" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
